document.addEventListener('DOMContentLoaded', () => {
  const screenWidth = document.documentElement.clientWidth;

  /**
   * 100vh - высота без учета панелей инструментов на мобильных
   */
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  const header = document.querySelector('.header').clientHeight;
  document.documentElement.style.setProperty('--header', `${header}px`);


  /**
   * Обложки статей в соотношении 3.6
   */
  const coversArticles = document.querySelectorAll('.article__pic');
  if (coversArticles.length) coversArticles.forEach(cover => cover.style.height = `${cover.clientWidth / 3.6}px`);

  /**
   * Обложки статей в соотношении 3.6
   */
  const coversCardsArt = document.querySelectorAll('.article-card__img');
  if (coversCardsArt.length) coversCardsArt.forEach(cover => cover.style.height = `${cover.clientWidth / 3.6}px`);

  /**
   * Соотношение сторон баннера акций 6:1 (ПК), 330:200 (мобилки, ниже 480px)
   */
  const bannersStock = document.querySelectorAll('.banner-stock');
  screenWidth <= 480 ?
    bannersStock.forEach(banner => banner.style.height = `${banner.clientWidth / 1.65}px`) :
    bannersStock.forEach(banner => banner.style.height = `${banner.clientWidth / 6}px`);


  /**
   * Если видно мобильное меню
   */
  if (screenWidth < 1200) {
    /**
     * Открытие мобильного меню
     */
    const burger = document.querySelector('.burger');
    burger.addEventListener('click', function(event) {
      this.classList.toggle('open');
      this
        .closest('.navigation')
        .querySelector('.navigation__content')
        .classList.toggle('open');

      this.classList.contains('open') ?
        document.querySelector('body').style.overflow = 'hidden' :
        document.querySelector('body').style.overflow = 'initial';
    });

    /**
     * Переключение открытой навигации
     */
    const arrDropdownButton = $('.navigation__dropdown_button');

    arrDropdownButton.on('click', function(event) {

      event.preventDefault();

      const buttonActive = $(this).hasClass('active');

      /**
       * Открываем нужный
       */
      if (!buttonActive) {
        $(this).parent().addClass('open');
        $(this).addClass('active');
      } else {
        $(this).parent().removeClass('open');
        $(this).removeClass('active');
      }

    })
  }


  /** Прилипание меню после прокрутки и изменение высоты мобильного меню */
  const firstMenu = document.querySelector('.navbar-first');
  const secondMenu = document.querySelector('.navbar-second');
  const secondMenuMb = +getComputedStyle(secondMenu).marginBottom.split('px').join('');
  const scrollHeight = firstMenu.clientHeight;
  const mb = secondMenu.clientHeight + secondMenuMb;

  function transferNavbarSecond() {
    window.pageYOffset > scrollHeight ?
      secondMenu.classList.add('navbar-second_glue') :
      secondMenu.classList.remove('navbar-second_glue');

    window.pageYOffset > scrollHeight ?
      firstMenu.style.marginBottom = `${mb}px` :
      firstMenu.style.marginBottom = '0px';
  }

  const menuMobile = document.querySelector('.navigation__content');
  const navbarSecond = document.querySelector('.navbar-second');

  function setHeightMenuMobile() {
    if (screenWidth < 1200) {
      const topOffsetNavbarSecond = navbarSecond.getBoundingClientRect().top;

      /** Рассчитываем высоту меню */
      menuMobile.style.height = topOffsetNavbarSecond > 0 ?
        window.innerHeight - (navbarSecond.clientHeight + topOffsetNavbarSecond) + 'px' :
        window.innerHeight - navbarSecond.clientHeight + 'px';
    }
  }

  transferNavbarSecond();
  setHeightMenuMobile();

  window.addEventListener('scroll', () => {
    setHeightMenuMobile();
    transferNavbarSecond();
  });

  window.onresize = setHeightMenuMobile;


  /** Инициализация слайдеров swiper */
  new Swiper('.swiper-main', {
    slidesPerView: 'auto',
    initialSlide: 0,
    centeredSlides: true,
    spaceBetween: 30,
    loop: true,
    autoplay: true,
    delay: 4000,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      prevEl: '.swiper-main__prev',
      nextEl: '.swiper-main__next'
    },
    slideToClickedSlide: true,
    preloadImages: false,
    lazy: true,
    loadOnTransitionStart: true
  });

  new Swiper('.banner-stock', {
    slidesPerView: 'auto',
    initialSlide: 0,
    centeredSlides: true,
    spaceBetween: 30,
    loop: true,
    autoplay: true,
    delay: 6000,
    pagination: {
      el: '.banner-stock__pagination',
      clickable: true,
    },
    navigation: {
      prevEl: '.banner-stock__prev',
      nextEl: '.banner-stock__next'
    },
    slideToClickedSlide: true,
    preloadImages: false,
  });

  new Swiper('.swiper-specialist', {
    initialSlide: 0,
    spaceBetween: 30,
    centeredSlides: false,
    allowTouchMove: false,
    navigation: {
      nextEl: '.specialists__next',
      prevEl: '.specialists__prev',
    },
    breakpoints: {
      480: {
        spaceBetween: 10,
        slidesPerView: 2
      },
      768: {
        spaceBetween: 20,
        slidesPerView: 3
      },
      992: {
        slidesPerView: 4
      },
      1200: {
        slidesPerView: 5
      },
      1500: {
        slidesPerView: 6
      }
    }
  });

  new Swiper('.article-slider', {
    centeredSlides: true,
    spaceBetween: 30,
    navigation: {
      prevEl: '.swiper-main__prev',
      nextEl: '.swiper-main__next'
    },
  });

  new Swiper('.swiper-comments', {
    initialSlide: 0,
    centeredSlides: true,
    loop: true,
    autoplay: false,
    navigation: {
      nextEl: '.comments__next',
      prevEl: '.comments__prev',
    },
  });

  new Swiper('.swiper-comments-2', {
    initialSlide: 0,
    centeredSlides: true,
    loop: true,
    autoplay: false,
    navigation: {
      nextEl: '.comments__next',
      prevEl: '.comments__prev',
    },
  });


  /**
   * После инициализации всех слайдеров с комментами скрываем их
   * видны будут только те у которых есть класс .active
   */
  const commentsWrap = document.querySelectorAll('.comments__swiper');
  commentsWrap.forEach(el => el.style.display = 'none');


  /** Табы внутри блока отзывов */
  const clinicWrap = document.querySelector('[data-wrap="clinic"]');
  const specialistWrap = document.querySelector('[data-wrap="specialist"]');
  const btnClinic = document.querySelector('[data-btn="clinic"]');
  const btnSpecialist = document.querySelector('[data-btn="specialist"]');

  function toggleFeedback() {
    btnClinic.classList.toggle('active');
    btnSpecialist.classList.toggle('active');
    clinicWrap.classList.toggle('active');
    specialistWrap.classList.toggle('active');
  }

  btnClinic && btnClinic.addEventListener('click', toggleFeedback);
  btnSpecialist && btnSpecialist.addEventListener('click', toggleFeedback);


  /**
   * Ленивая загрузка видео
   * событие скролла удаляется по достижению 50% прокрутки до видео
   */
  const videoWrap = document.querySelector('.video');

  if (videoWrap) {
    const video = videoWrap.querySelector('video');
    const videoScrollTop = Math.ceil(video.getBoundingClientRect().top);

    window.addEventListener('scroll', loadVideo);

    function loadVideo() {
      const scrollTop = window.pageYOffset;

      if (scrollTop > videoScrollTop / 2) {
        window.removeEventListener('scroll', loadVideo);

        const source = video.querySelector('source');
        const urlVideo = source.dataset.src;
        source.setAttribute('src', urlVideo);
        video.load();

        /** Вешаем обработчик на воспроизведение/остановку видео */
        videoWrap.addEventListener('click', async event => {

          if (video.paused) {
            await video.play();
            videoWrap.style.background = 'url(../images/other/pattern.png) center repeat';
            video.controls = true;
          } else {
            video.pause();
            video.controls = false;
          }

          event.target.closest('.video').classList.toggle('play');
        });
      }
    }
  }


  /** Календарь в модалке */
  $('#datepicker').datepicker({
    minDate: new Date(),
    onSelect: function(dateText) {
      $('#appointment-date').val(dateText);
    }
  });


  /** Фокус на поиске */
  $(".filter-panel__input-search").focus().trigger('click');




  /** Инициализация модалок */
  const modalCallFeedback = new Modal({
    idModal: 'modal-call-feedback',
    selectorBtnOpen: '[data-btn="modal-call-feedback-btn"]'
  });

  const modalDoctorCall = new Modal({
    idModal: 'modal-doctor-call',
    selectorBtnOpen: '[data-btn="modal-doctor-call-btn"]'
  });

  const modalAppointment = new Modal({
    idModal: 'modal-appointment',
    selectorBtnOpen: '[data-btn="modal-appointment-btn"]'
  });

  const modalFeedback = new Modal({
    idModal: 'modal-feedback',
    selectorBtnOpen: '[data-btn="modal-feedback-btn"]'
  });

  const modalThanksFeedback = new Modal({
    idModal: 'modal-thanks-feedback',
    selectorBtnOpen: '[data-btn="modal-thanks-feedback-btn"]'
  });

  const modalThanksAppointment = new Modal({
    idModal: 'modal-thanks-appointment',
    selectorBtnOpen: '[data-btn="modal-thanks-appointment-btn"]'
  });

	const modalCallAnalyzes = new Modal({
    idModal: "modal-call-analyzes",
    selectorBtnOpen: '[data-btn="modal-call-analyzes-btn"]',
  });


  /** Проверка согласия на обработку персональных данных */
  function checkAgree() {
    const btn = this.closest('form').querySelector('.button');
    btn.toggleAttribute('disabled');
  }
  document.getElementById('call-feedback-checkbox').addEventListener('change', checkAgree);
  document.getElementById('doctor-call-checkbox').addEventListener('change', checkAgree);
  document.getElementById('appointment-checkbox').addEventListener('change', checkAgree);
  document.getElementById("call-analyzes-checkbox").addEventListener("change", checkAgree);


  /** Плавная прокрутка до якоря */
  const anchors = document.querySelectorAll('a[href*="$"]');
  if (anchors.length) {
    anchors.forEach(anchor => {
      anchor.addEventListener('click', function(e) {
        e.preventDefault();

        const elementClick = this.getAttribute('href').split('');
        elementClick.splice(0, 1, '#');
        const normalName = elementClick.join('');
        const destination = $(normalName).offset().top - 130;

        $("html:not(:animated),body:not(:animated)").animate({ scrollTop: destination }, 1100);
      })
    });
  }


  /** Маска номера телефона на поля. Maskedinput */
  $("#call-feedback-tel").mask("+7 (999) 999-9999");
  $("#call-doctor-call-tel").mask("+7 (999) 999-9999");
  $("#call-appointment-tel").mask("+7 (999) 999-9999");
  $("#call-analyzes-tel").mask("+7 (999) 999-9999");


  /** Инициализация галереи fancybox */
  document.querySelector('[data-fancybox="gallery"]') && $('[data-fancybox="gallery"]').fancybox({
    toolbar: false,
    transitionEffect: "zoom-in-out",
    loop: true
  });


  /** Аккордеоны */
  const accordions = document.querySelectorAll('[data-accordion]');
  accordions.forEach(accordion => {
    const spoilers = accordion.querySelectorAll('.spoiler');
    spoilers.forEach(spoiler => {
      const spoilerObj = new Spoiler({
        spoiler,
        wrap: accordion
      })

      spoiler.dataset.spoilerStart === 'open' && spoilerObj.open();
    });
  });

  /** Спойлеры */
  const spoilers = document.querySelectorAll('[data-spoiler]');
  spoilers.forEach(spoiler => {
    new Spoiler({
      spoiler
    })
  });
});


/** Управляем событиями */
class ManagementEvents {

  /**
   * На входе принимаем объект с параметрами
   * arr   - массив куда сохраняем события
   * el    - элемент, на который навешиваем обработчик
   * event - событие (str)
   * fn    - анонимная функция без вызова
   * @param data
   */
  static addEventToArr(data) {
    /** Добавляем событие в массив */
    data.arr.push({ el: data.el, event: data.event, fn: data.fn });

    /** Вешаем слушатель */
    data.el.addEventListener(data.event, data.fn);

    return true
  }

  /**
   * На входе принимаем массив событий
   * @param arr
   */
  static removeEvents(arr) {
    /** Снимаем обработчики события */
    arr.forEach(item => {
      item.el.removeEventListener(item.event, item.fn)
    });

    /** Очищаем массив */
    arr.splice(0, arr.length);

    return true;
  }
}

class Modal {
  constructor(data) {
    this.arrEvents = [];
    this._modal = document.getElementById(data.idModal);
    this._modalWindow = this._modal.querySelector('[data-modal="window"]');

    this._btnsOpen = document.querySelectorAll(data.selectorBtnOpen);
    this._btnClose = this._modal.querySelector('[data-modal="close"]');

    this.bind();
  }

  open() {
    this._modal.classList.add('modal_open');
    this._modalWindow.classList.add('modal__window_open');
  }
  close() {
    this._modal.classList.remove('modal_open');
    this._modalWindow.classList.remove('modal__window_open');
  }

  bind() {
    /**
     * Открытие модалки по клику на кнопку
     */
    this._btnsOpen.forEach(btn => {
      ManagementEvents.addEventToArr({
        arr: this.arrEvents,
        el: btn,
        event: 'click',
        fn: () => this.open()
      });
    });


    /**
     * Закрытие модалки по клику вне нее
     */
    ManagementEvents.addEventToArr({
      arr: this.arrEvents,
      el: this._modal,
      event: 'click',
      fn: event => !event.target.closest('[data-modal=window]') && this.close()
    });

    /**
     * Закрытие модалки по клику на крестик
     */
    ManagementEvents.addEventToArr({
      arr: this.arrEvents,
      el: this._btnClose,
      event: 'click',
      fn: () => this.close()
    });
  }

  destroy() {
    ManagementEvents.removeEvents(this.arrEvents);
    this._modal.remove();
  }
}

class Spoiler {
  constructor(data) {
    this.spoiler = data.spoiler;
    this.body = this.spoiler.querySelector('.spoiler__body');
    this.wrap = data.wrap;
    this.spoilers = null;
    this.height = null;

    this._initialize();
  }

  open() {
    this.spoiler.classList.add('open');
    this.body.style.height = `${this.height}px`;
  }

  close() {
    this.spoiler.classList.remove('open');
    this.body.style.height = '0px';
  }

  closeAll() {
    this.spoilers.forEach(spoiler => {
      spoiler.classList.remove('open');
      spoiler.querySelector('.spoiler__body').style.height = '0px';
    });
  }

  _initialize() {
    this.height = this.body.clientHeight;
    this.body.style.height = '0px';

    /** если есть this.wrap, значит включен режим аккордеона */
    if (this.wrap) this.spoilers = this.wrap.querySelectorAll('.spoiler');

    this.spoiler.addEventListener('click', event => {
      const spoiler = event.target.closest('.spoiler');
      if (!spoiler) return;

      /** открываем или закрываем спойлер */
      const open = spoiler.classList.contains('open');
      if (open) {
        this.close();
      } else {
        this.wrap && this.closeAll();
        this.open();
      }
    });
  }
}

$(function () {
  $('.menu-other__btn-link').on('click', function (event) {
    event.preventDefault()
    $('.menu-other__btn-link').removeClass('active')
    $(this).addClass('active')

    localStorage.href = this.getAttribute('href')

    let href = $(this).attr('href');
    $('.menu-other__column').removeClass('active');
    $(href).addClass('active');

     localStorage.blockId = $('div[class="menu-other__column active"]').attr('id')
  })
  if(localStorage.href !== undefined) {
    $(window).on('load', function () {
      let blockId = localStorage.blockId;
      let href = localStorage.href;
      $('a[href="'+href+'"]').addClass('active')
      $('div[id="'+blockId+'"]').addClass('active')
    })
  } else {
    $('a[href="#btn-link1"]').addClass('active')
    $('#btn-link1').addClass('active')
  }
})


$(document).ready(function () {
  let analyzesArr = [];
  // Скрытие, раскрытие блока калькулятор
  $(".cost-calculate__header").on("click", function () {
    $(this).next(".cost-calculate__body").slideToggle();
  });
  // Выводим выбранные чеки вниз
  $(".cost-calculate__checkbox-hidden").on("change", function () {
    const price = $(this).attr("data-price");
    const value = $(this).attr("data-title");
    const priceId = $(this).attr("data-id");
    if ($(this).prop("checked")) {
      $(".cost-result").append(`
				<li class="cost-result__row" data-result="${priceId}">
					<div class="cost-result__row-left">
						<h3 class="cost-result__row-title">${value}</h3>
						<span class="cost-result__row-price">${price} руб.</span>
					</div>
					<div class="cost-result__row-right">
						<button class="cost-result__row-del" type="button"></button>
					</div>
				</li>`);
      $("#totalPrice").html(sumPrice() + " руб.");
      analyzesArr.push(priceId);
      // Удаляем данные из списка
      $(`[data-result=${priceId}]`).on(
        "click",
        ".cost-result__row-del",
        function () {
          $(this).closest(".cost-result__row").remove();
          $(`[data-id=${priceId}]`).prop("checked", false).trigger("change");
        }
      );
      $("#analyzesInput").val(JSON.stringify(analyzesArr));
    } else {
      $(`[data-result=${priceId}]`).remove();
      $("#totalPrice").html(sumPrice() + " руб.");
      let analyzesIndex = analyzesArr.indexOf(priceId);
      if (analyzesIndex !== -1) {
        analyzesArr.splice(analyzesIndex, 1);
      }
      $("#analyzesInput").val(JSON.stringify(analyzesArr));
      return false;
    }
  });
	// Если нужно статично сгенерировать id на странице калькулятор
  // $(".cost-calculate__checkbox-hidden").attr("data-id", function (index) {
  //   return index;
  // });
  // Поиск на странице
  let searchArr = [];
  let $searchTitle = $(".cost-calculate__checkbox-hidden");
	// рендер подходящих подсказок
  $(".filter-panel__analyzes-search").keyup(function (event) {
    switch (event.keyCode) {
      case 9: // tab
      case 13: // enter
      case 16: // Shift
      case 17: // ctrl
      case 19: // alt
      case 20: // CAPSlOCK
      case 27: // escape
      case 32: // space
      case 37: // стрелка лево
      case 38: // стрелка вверх
      case 39: // стрелка право
      case 40: // стрелка вниз
        break;

      default:
        if ($(this).val().length > 3) {
          $(".filter-panel__analyzes-item").remove();
          searchArr = searchAnalyzesTitle($(this).val(), $searchTitle);
          if (searchArr.length) {
            searchArr.forEach((element) => {
              $(".filter-panel__analyzes-body").append(`
								<li class="filter-panel__analyzes-item">
									<button href="#" class="filter-panel__analyzes-link" data-link="${element.dataId}">${element.dataTitle}</button>
								</li>
							`);
            });
          } else {
            return false;
          }
        } else {
          $(".filter-panel__analyzes-item").remove();
          return false;
        }
        break;
    }
		if ($(this).val()) {
      $(".filter-panel__analyzes-search").addClass("active");
      $(".filter-panel__analyzes-body").addClass("active");
    } else {
			$(".filter-panel__analyzes-body").removeClass("active");
      $(".filter-panel__analyzes-search").removeClass("active");
		}
  });
  $(document).on("click", ".filter-panel__analyzes-link", function (e) {
		e.preventDefault
    if (!analyzesArr.includes($(this).attr("data-link"))) {
      $('.cost-calculate__checkbox-hidden[data-id="' +$(this).attr("data-link") +'"]')
        .prop("checked", true)
        .trigger("change");
    } else {
			$('.cost-calculate__checkbox-hidden[data-id="' +$(this).attr("data-link") +'"]')
        .prop("checked", false)
        .trigger("change");
		}
  });
	$(window).on('click', function () {
		$(".filter-panel__analyzes-body").removeClass("active");
		$(".filter-panel__analyzes-search").removeClass("active");
	})
});


// Добавляем элементы в массив
function searchAnalyzesTitle(string, searchList) {
	let tempArr = [];
	searchList.each(function () {
     if (new RegExp(string, "i").test($(this).attr("data-title"))) {
       tempArr.push({
         dataTitle: $(this).attr("data-title"),
         dataId: $(this).attr("data-id"),
       });
     }
  });
	return tempArr;
}

// Подсчёт общей суммы
function sumPrice() {
	let sum = 0;
    $("[data-price]:checked").each(function () {
      sum += parseInt($(this).attr("data-price"));
    });
	return sum
}

// Выделить всё
$(".cost-calculate__body-allBtn").on("click", function (e) {
	e.preventDefault;
	$(this).toggleClass("active");
	if ($(this).hasClass("active")) {
		$(this)
			.html("Снять все")
			.next(".cost-calculate__body-list")
			.find(".cost-calculate__checkbox-hidden")
			.prop("checked", true)
			.trigger('change');
	} else {
		$(this)
			.html("Выделить все")
			.next(".cost-calculate__body-list")
			.find(".cost-calculate__checkbox-hidden")
			.prop("checked", false)
			.trigger('change');
	}
})

